import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/vanatu-team/vanatu-app-landing-page/src/layout/DefaultPageLayout.tsx";
import { LandingScreen } from '../screens/LandingScreen';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <LandingScreen {...props} mdxType="LandingScreen" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      